// generated on build time, from script >> iac/scripts/build-environment-conf.sh

export const environment = {
  production: true,
  scraperMock: true,
  env: 'qa',
  baseUrl: 'https://api.qa.belender.net',
  notificationsBaseUrl: 'wss://notifications.qa.belender.net'
};


